<template>
  <div id="Manage">
    <!-- 基础设置 卡券管理-->

    <!-- Dialog 对话框 -->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
      :visible.sync="dialogFonfig.isShowDialogVisible" @close="clickCancelData">
      <CommonForm :form="openForm" :formLabel="openFormLabel" ref="form"></CommonForm>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary" @click="clickSubmitData(dialogFonfig.openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <!-- <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">添加卡券类型</el-button> -->

          <CommonForm :form="searchForm" :formLabel="searchformLabel" class="filtrateInput">
            <el-button type="primary" @click="searchUSer(searchForm)">搜索</el-button>
            <el-button>导出</el-button>
            <el-button type="text">查看已导出列表</el-button>
            <el-button type="text" @click="resetFilter(searchForm)">重置筛选条件</el-button>
            <div class="line">—</div>
          </CommonForm>
        </div>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData" :tableLabel="tableLabel" :tableConfig="tableConfig" @getPage="getPage">
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false" class="item" effect="dark" content="编辑" placement="top">
                <span @click="clickEditData(scope.$index, scope.row)">编辑</span>
              </el-tooltip>

            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { getweiaiUserList, metWeiaiUserList, getweiaiBankPoint } from '../../../api/weiaiApi'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data() {
    return {
      searchData: '',// 存放搜索的数据，分页时使用
      allBankPointData: [],// 银行网点的所有数据
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        // openType: 'add',
        // addName: '新增卡券类型信息',
        editName: '编辑用户信息',
        isShowDialogVisible: false
      },

      // form表单 基础数据
      openForm: {
        bank_point_id: '', // 网点id
        name: '', // 姓名
        phone: '', // 手机
        id_card: '' // 身份证
      },
      openFormLabel: [
        {
          model: 'name',
          label: '姓名'
        },
        {
          model: 'phone',
          label: '手机'
        },
        {
          model: 'id_card',
          label: '身份证'
        },
        {
          model: 'bank_point_id',
          label: '网点id',
          type: 'select',
          options: [
            { value: 0, label: '冻结' },
            { value: 1, label: '正常' }
          ],
          required: true
        },
      ],

      // form表单 搜索数据
      searchForm: {
        bank_point_id: '',//网点id
        name: '', //姓名
        phone: '', // 手机
        id_card: ''// 身份证
      },
      searchformLabel: [
        {
          model: 'bank_point_id',
          label: '注册网点',
          type: 'select',
          options: []
        },
        {
          model: 'name',
          label: '姓名'
        },
        {
          model: 'phone',
          label: '手机号',
          width: '300px'
        },
        {
          model: 'id_card',
          label: '身份证号',
          width: '400px'
        },
      ],
      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'id',
          label: '用户ID'
        },
        {
          prop: 'name',
          label: '姓名/账户'
        },
        {
          prop: 'phone',
          label: '手机'
        },
        {
          prop: 'id_card',
          label: '身份证'
        },
        {
          prop: 'bank_point_id',
          label: '注册网点'
        },
        {
          prop: 'create_time',
          label: '注册时间'
        },
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false
      },

      // 用户-全部-数据
      userAlldataObj: {}

    }
  },
  mounted() {
    this.getweiaiBankPoint();
    this.getweiaiUserList()
  },
  beforeUpdate() {
  },
  watch: {
    userAlldataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.userAlldataObj.list,
        {
        }
      )
    },
    allBankPointData: function () {
      this.searchformLabel[0].options = this.dataFormatDeal(this.allBankPointData, {
        id: 'value',
        name: 'label',
        child: 'children'
      }, 1)
    }
  },

  methods: {
    // 获得下列分类页的页数
    getPage() {
      if (this.searchData) {
        this.searchData.page = this.tableConfig.page
        this.getweiaiUserList(this.searchData)
      } else {
        this.getweiaiUserList()
      }
    },

    // 获取银行网点的数据
    getweiaiBankPoint() {
      getweiaiBankPoint().then(res => {
        this.allBankPointData = res.data.data.list
        console.log(this.allBankPointData)
      })
    },
    // 获取--用户列表数据
    getweiaiUserList(data) {
      if (data) {
        getweiaiUserList(data).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code === 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.userAlldataObj = DATA.data
            this.tableConfig.total = DATA.data.total
          }
        })
      } else {
        getweiaiUserList({
          page: this.tableConfig.page,
          page_rows: 10
        }).then(res => {
          const DATA = res.data
          if (DATA.code === 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.userAlldataObj = DATA.data

            this.tableConfig.total = DATA.data.total
            this.tableConfig.loading = false
          }
        })
      }
    },

    // 编辑用户信息
    metWeiaiUserList(data) {
      return new Promise(resolve => {
        metWeiaiUserList(data).then(res => {
          const DATA = res.data
          resolve(DATA)
        })
      })
    },

    // 数据格式处理
    dataFormatDeal(arr, keyMap, copyInKeyMap) {
      // global 全局方法处理

      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 确定提交数据操作
    async clickSubmitData(type, data) {
      console.log(data)

      if (type === 'edit') {
        const DATA = {
          bank_point_id: data.bank_point_id, // 网点id
          name: data.name, // 姓名
          phone: data.phone, // 手机
          id_card: data.id_card // 身份证
        }
        let res = await this.metWeiaiUserList(DATA)
        console.log(res.code)
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.getweiaiUserList()
          this.updataTableData()
          this.dialogFonfig.isShowDialogVisible = false
          this.openForm = this.$options.data().openForm;
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          })
        }
      }
    },
    // 取消提交数据操作
    clickCancelData() {
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
    },

    // // 添加数据
    // clickAddData () {
    //   this.dialogFonfig.isShowDialogVisible = true

    //   console.log(this.cardAlldataObj.list)

    // },
    // 编辑操作
    async clickEditData(idx, row) {
      this.openForm = {
        bank_point_id: row.bank_point_id,//网点id
        name: row.name,   //	姓名
        phone: row.phone,//	手机
        id_card: row.id_card,//	身份证
      }
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    //刷新表格
    updataTableData() {
      this.getweiaiUserList()
    },
    //搜索用户
    searchUSer(searchForm) {
      const data = {
        bank_point_id: searchForm.bank_point_id,//网点id
        name: searchForm.name,   //	姓名
        phone: searchForm.phone,//	手机
        id_card: searchForm.id_card,//	身份证
      }
      this.searchData = data
      this.getweiaiUserList(data)
    },
    //重置筛选条件
    resetFilter(searchForm) {
      searchForm.bank_point_id = '';
      searchForm.name = '';
      searchForm.phone = ''
      searchForm.id_card = ''
      this.searchData = ""
      this.getweiaiUserList()
    },
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;

  .el-transfer {
    text-align: center;

    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }

  .filtrateInput {
    background: #f8f7f7;
    width: 69%;
    padding: 12px 8px;
    padding-right: 45%;
    margin: 8px 0px;
    position: relative;

    .line {
      position: absolute;
      top: -49px;
      left: 692px;
      color: #d8d8d8;
    }
  }

  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }

  /deep/ .el-dialog {
    width: 30%;
  }
}
</style>
